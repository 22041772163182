import {useState} from "react";
import PrimaryButton from "./PrimaryButton";

export default function SelectField({placeholder, options = [], setSource, createProjectModalSwitcher}) {

    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [opened, setOpened] = useState(false);

    return (
        <div>
            <div className={'p-5 rounded-xl border max-w-64 w-64 flex justify-between items-center'}
                 onClick={() => setOpened((prev) => !prev)}>
                {
                    selectedIndex >= 0 ? (
                        options[selectedIndex].display
                    ) : (
                        placeholder
                    )
                }
                {
                    opened ? (
                        <i className='bx bxs-chevron-up'></i>
                    ) : (
                        <i class='bx bx-chevron-down'></i>
                    )
                }
            </div>

            {
                opened && (
                    <div className={'absolute rounded-xl bg-white text-black overflow-hidden w-64 border z-50'}>
                        {
                            options.map((option, index) =>
                                <div className={'p-5 hover:bg-gray-200 transition-all cursor-pointer'} onClick={() => {
                                    setSelectedIndex(index);
                                    setOpened(false);
                                    setSource(options[index].value);
                                }}>
                                    {option.display}
                                </div>
                            )
                        }
                        <div className={'p-5'}>
                            <PrimaryButton onClick={() => {
                                createProjectModalSwitcher(true)
                                setOpened(false)
                            }} fullWidth={true}>Create new</PrimaryButton>
                        </div>

                    </div>
                )
            }
        </div>
    )
}
import logo from '../logo.svg';
import '../App.css';
import bell from '../media/bell.svg'
import mobile from '../media/mobile.svg'
import key from '../media/key.svg'
import PrimaryButton from "../components/PrimaryButton";
import WhiteButton from "../components/WhiteButton";
import TransparentButton from "../components/TransparentButton";
import Demo from "../components/other/demo";
import {Helmet} from "react-helmet";
import Headmenu from "../components/Headmenu";
import Footer from "../components/footer";


function Home() {

    const plans = [
        {
            name: "Freemium",
            cost: 'Free',
            notificationLimit: 500,
            authorizationAvailable: false,
            customBotAvailable: false,
        },
        {
            name: "Basic",
            cost: '$15 / mo.',
            notificationLimit: 2000,
            authorizationAvailable: false,
            customBotAvailable: true,
        },
        {
            name: "Pro",
            cost: '$25 / mo.',
            notificationLimit: 5000,
            authorizationAvailable: true,
            customBotAvailable: true,
        },
        {
            name: "Enterprise",
            cost: 'Contact us',
            notificationLimit: 'unlimited',
            authorizationAvailable: true,
            customBotAvailable: true,
        },
    ]

    return (
        <>
            <Headmenu/>
            <Helmet>
                <title>Notifications for your users with Beep</title>
                <meta name="description"
                      content="Beep — a service for sending notifications to your users via API. Easy to start, easy to use. Start free now."/>
                <meta name="keywords"
                      content="notifications, manage, buisness, beep, notifications for buisness, telegram"/>
                <link rel="canonical" href="https://beepme.org/"/>

                <meta property="og:title" content="Beep - Notifications for your buisness"/>
                <meta property="og:description" content="Start sending notifications free"/>
                <meta property="og:url" content="https://beepme.org/"/>
                <meta property="og:type" content="website"/>

            </Helmet>
            <div className={'w-screen min-h-screen bg-[#EEF6FF] z-0 pb-20 max-sm:p-5 overflow-hidden'}>
                <div className={'container m-auto'}>

                    <div className={'h-screen flex justify-center items-center relative'}>
                        <div className={'flex flex-col gap-5 z-20'}>
                            <div className={'p-20 max-sm:p-5 bg-white rounded-xl relative'}>
                                <p className={'text-sm text-gray-500'}>Oh! You have a</p>
                                <h1 className={'text-9xl font-bold'}>beep!</h1>
                                <p className={'mt-5 text-xl'}>Easy notifications and authentication in Telegram</p>
                                <p className={'mt-5'}>Use Beep for sending notifications and authenticate your users all
                                    around
                                    the world
                                    via Telegram.</p>

                                <div className={'mt-5 text-center flex justify-center'}>
                                    <a href="https://www.producthunt.com/posts/beep-notifications-authentication?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-beep&#0045;notifications&#0045;authentication"
                                       target="_blank"><img
                                        src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=838173&theme=light&t=1738352446772"
                                        alt="Beep&#0032;&#0045;&#0032;Notifications&#0032;&#0038;&#0032;Authentication - Emails&#0032;are&#0032;outdated&#0046;&#0032;Notify&#0032;users&#0032;where&#0032;they&#0032;are&#0046; | Product Hunt"
                                        style={{width: "250px", height: "54px", width: "250", height: "54"}}/></a>
                                </div>
                            </div>
                            <div
                                className={'p-10 bg-blue-500 text-white rounded-xl border-2 border-black text-start flex justify-between max-lg:flex-col max-lg:gap-10 lg:items-center'}>
                                <div>
                                    <h3 className={'text-3xl'}>Free for now ⛓️‍💥</h3>
                                    <p>You can create anything you want for free</p>
                                </div>
                                <a href={'/login'}><WhiteButton onClick={() => {
                                }} fullWidth={true}> Start now</WhiteButton></a>
                            </div>
                            <a href={'/login'}><PrimaryButton fullWidth={true} onClick={() => {
                            }}>Get started for
                                FREE</PrimaryButton></a>
                            <a href={'#plans'}><TransparentButton fullWidth={true} onClick={() => {
                            }}>See all plans</TransparentButton></a>
                        </div>
                        <img src={bell} alt={'Bell image'}
                             className={'absolute z-5 -translate-x-1/2'}/>
                        <img src={bell} alt={'Bell image'}
                             className={'absolute z-5 translate-x-1/2 -scale-x-100'}/>
                    </div>

                    <div className={'flex flex-col gap-10'}>
                        <div className={'bg-white rounded-xl p-20 max-sm:p-5'}>
                            <div className={'flex max-sm:flex-col items-center'}>
                                <img src={mobile} alt={'Mobile image'} className={'w-1/2 hidden max-sm:block'}/>
                                <div className={'w-1/2 max-sm:w-full'}>
                                    <h2 className={'text-6xl mb-5'}>Send notifications via Telegram</h2>
                                    <p>Ensure your customers and users receive important updates quickly, reliably, and
                                        conveniently with Beep. Your audience will get notifications directly in
                                        Telegram —
                                        one
                                        of the most popular and secure messaging platforms.</p>
                                </div>
                                <img src={mobile} alt={'Mobile image'} className={'w-1/2 max-sm:hidden'}/>
                            </div>
                        </div>
                        <div className={'bg-white rounded-xl p-20 max-sm:p-5'}>
                            <div className={'flex max-sm:flex-col items-center'}>
                                <img src={key} alt={'Key image'} className={'w-1/2'}/>
                                <div className={'w-1/2 text-end max-sm:text-start max-sm:w-full'}>
                                    <h2 className={'text-6xl mb-5'}>Authenticate your users</h2>
                                    <p>Simplify user management and enhance security with Beep’s Telegram-based
                                        authentication.
                                        Forget about building and maintaining separate user accounts — Beep handles
                                        everything
                                        for you, ensuring a fast and hassle-free experience for both developers and
                                        users.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={'mt-20 flex max-sm:flex-col gap-10 items-start'}>
                        <div className={'bg-white rounded-xl p-20 max-sm:p-5'}>
                            <h2 className={'text-6xl mb-5'}>Try out user experience</h2>
                            <p>Let us walk you through the user journey and we'll tell you how things work here.</p>
                        </div>
                        <Demo/>
                    </div>

                    <div className={'mt-20 text-center relative'} id={'plans'}>
                        <h2 className={'text-6xl'}>Plans</h2>
                        <div
                            className={'p-10 bg-blue-500 text-white rounded-xl border-2 border-black text-start mt-5 flex justify-between max-lg:flex-col max-lg:gap-10 lg:items-center'}>
                            <div>
                                <h3 className={'text-3xl'}>Free for now ⛓️‍💥</h3>
                                <p>You can create anything you want for free</p>
                            </div>
                            <a href={'/login'}><WhiteButton onClick={() => {
                            }} fullWidth={true}> Start now</WhiteButton></a>
                        </div>

                        {/*<div className={'flex max-sm:flex-col gap-10 mt-10'}>*/}
                        {/*    {*/}
                        {/*        plans.map((plan) =>*/}
                        {/*            <div className={'p-10 rounded-xl bg-white text-black flex-1'}>*/}
                        {/*                <p>{plan.cost}</p>*/}
                        {/*                <p className={'text-4xl'}>{plan.name}</p>*/}

                        {/*                <div className={'mt-10 flex flex-col gap-5'}>*/}
                        {/*                    <div>*/}
                        {/*                        <p>Notifications</p>*/}
                        {/*                        <p className={'text-2xl'}>{plan.notificationLimit} / mo.</p>*/}
                        {/*                    </div>*/}

                        {/*                    <div>*/}
                        {/*                        <p>Custom bot</p>*/}
                        {/*                        {*/}
                        {/*                            plan.customBotAvailable ? (*/}
                        {/*                                <p className={'text-green-500 text-2xl flex items-center justify-center gap-1'}>*/}
                        {/*                                    <i*/}
                        {/*                                        className='bx bxs-check-circle'></i>*/}
                        {/*                                    <label>Available</label></p>*/}
                        {/*                            ) : (*/}
                        {/*                                <p className={'text-red-500 text-2xl flex items-center justify-center'}>*/}
                        {/*                                    <i*/}
                        {/*                                        className='bx bxs-x-circle'></i> Not available</p>*/}
                        {/*                            )*/}
                        {/*                        }*/}
                        {/*                    </div>*/}

                        {/*                    <div>*/}
                        {/*                        <p>Authorization</p>*/}
                        {/*                        {*/}
                        {/*                            plan.authorizationAvailable ? (*/}
                        {/*                                <p className={'text-green-500 text-2xl flex items-center justify-center gap-1'}>*/}
                        {/*                                    <i*/}
                        {/*                                        className='bx bxs-check-circle'></i>*/}
                        {/*                                    <label>Available</label></p>*/}
                        {/*                            ) : (*/}
                        {/*                                <p className={'text-red-500 text-2xl flex items-center justify-center'}>*/}
                        {/*                                    <i*/}
                        {/*                                        className='bx bxs-x-circle'></i> Not available</p>*/}
                        {/*                            )*/}
                        {/*                        }*/}
                        {/*                    </div>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*        )*/}
                        {/*    }*/}


                        {/*</div>*/}
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    )
        ;
}

export default Home;

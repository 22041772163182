import {useState} from "react";
import NotificationsDemo from "./demos/NotificationsDemo";
import AuthorizationDemo from "./demos/AuthorizationDemo";

export default function Demo() {

    const [actionsAvailable, setActionsAvailable] = useState(['🔔 Notifications', '🔑 Authenticated'])
    const [action, setAction] = useState(0)

    const [demoes, setDemoes] = useState([
        <NotificationsDemo />,
        <AuthorizationDemo />
    ])

    return (
        <div className={'flex flex-col gap-5 w-full'}>
            <div className={'flex gap-5'}>
                {
                    actionsAvailable.map((item, index) =>
                        <div className={'p-5 rounded-xl flex-1 bg-white text-center cursor-pointer border-2 ' + (index === action ? ' border-black' : ' border-white')} onClick={() => setAction(index)}>
                            {item}
                        </div>
                    )
                }
            </div>
            <div className={'bg-white rounded-xl p-20 max-sm:p-5'}>
                {demoes[action]}
            </div>
        </div>

    )
}
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Home from './pages/Home';
import reportWebVitals from './reportWebVitals';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import DeveloperLogin from "./pages/DeveloperLogin";
import Dashboard from "./pages/Dashboard";
import NotificationsHistory from "./pages/NotificationsHistory";
import DevConnect from "./pages/DevConnect";
import ErrorBoundary from "./components/ErrorBoundary";
import NotFound from "./components/NotFound";

const router = createBrowserRouter([
    {
        path: "/",
        element: <ErrorBoundary><Home /></ErrorBoundary>
    },
    {
        path: "/login",
        element: <ErrorBoundary><DeveloperLogin /></ErrorBoundary>
    },
    {
        path: "/dashboard",
        element: <ErrorBoundary><Dashboard /></ErrorBoundary>
    },
    {
        path: "/dashboard/notifications/:projectId/history",
        element: <ErrorBoundary><NotificationsHistory /></ErrorBoundary>
    },
    {
        path: "/dev-connect",
        element: <ErrorBoundary><DevConnect /></ErrorBoundary>
    },
    {
        path: "*",
        element: <NotFound />
    }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router}/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

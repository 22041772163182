import {useId, useState} from "react";

export default function TransparentButton({onClick, children, fullWidth=false}) {

    const id = Math.random().toString(36).substr(2);
    const [processing, setProcessing] = useState(false);

    async function RunOnClick() {
        setProcessing(true);
        await onClick();
        setProcessing(false);
    }

    return (
        <>
            {
                processing ? (
                    <div>
                        <i className='bx bxs-color bx-spin text-2xl'></i>
                    </div>
                ) : (
                    <button
                        id={id}
                        className={'pt-3 pb-3 ps-10 pe-10 text-black border border-black hover:scale-95 transition-all rounded-xl ' + (fullWidth && ('w-full'))}
                        onClick={RunOnClick}>
                        {children}
                    </button>
                )
            }
        </>
    )
}
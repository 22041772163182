import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import apiClient from "../api/DevAPI";
import Loader from "../components/Loader";

export default function NotificationsHistory() {

    const {projectId} = useParams();

    const [history, setHistory] = useState(null);
    const [page, setPage] = useState(1);
    const [pagesAvailable, setPagesAvailable] = useState([]);

    useEffect(() => {
        apiClient.get(`/projects/${projectId}/notifications/?token=${localStorage.getItem('token')}`)
            .then(res => {
                setHistory(res.data);
                let pages = Math.ceil(res.data.total / res.data.on_page)
                let p = []
                for (let i = 1; i <= pages; i++) {
                    p.push(i)
                }
                setPagesAvailable(p)
            })
    }, [projectId]);

    useEffect(() => {
        setHistory(null);
        apiClient.get(`/projects/${projectId}/notifications/?token=${localStorage.getItem('token')}&page=${page}`)
            .then(res => {
                setHistory(res.data);
            })
    }, [page])

    return (
        <div className={'container m-auto'}>
            <div className={'mt-10'}>
                <h1 className={'text-3xl'}>Notifications service history</h1>
                <p>Project ID (keep in secret): {projectId}</p>

                {
                    history ? (
                        <>
                            <div className={'flex items-center gap-5'}>
                                {
                                    page > 1 && (
                                        <span className={'text-blue-400 decoration-dashed underline cursor-pointer'} onClick={() => setPage((prev) => prev-1)}>Previous</span>
                                    )
                                }
                                <select value={page} onChange={(e) => setPage(e.target.value)}>
                                    {
                                        pagesAvailable.map((p) =>
                                            page === p ? (
                                                <option value={p} selected={true}>{p}</option>
                                            ) : (
                                                <option value={p}>{p}</option>
                                            )
                                        )
                                    }
                                </select>
                                {
                                    page < pagesAvailable.length && (
                                        <span className={'text-blue-400 decoration-dashed underline cursor-pointer'} onClick={() => setPage((prev) => Number(prev)+1)}>Next</span>
                                    )
                                }
                            </div>
                            <div class="relative overflow-x-auto mt-10">

                                <table class="w-full text-sm text-left rtl:text-right text-gray-400">
                                    <thead
                                        class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                    <tr>
                                        <th scope="col" className="px-6 py-3">
                                            ID
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Source
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            To
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Title
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Date
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Status
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        history.data.sort((a, b) => b.id - a.id).map(unit =>
                                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                                <th scope="row"
                                                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                    {unit.id}
                                                </th>
                                                <th scope="row"
                                                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                    {unit.source}
                                                </th>
                                                <td className="px-6 py-4">
                                                    {unit.receiver}
                                                </td>
                                                <td className="px-6 py-4">
                                                    {unit.title}
                                                </td>
                                                <td className="px-6 py-4">
                                                    {new Date(unit.date).toLocaleString("ru-ru")}
                                                </td>
                                                <td className="px-6 py-4">
                                                    {
                                                        unit.is_sent ? (
                                                            <>
                                                            <span
                                                                data-tooltip-target={"tooltip-status-" + String(unit.id)}
                                                                className={'text-green-500'}><i
                                                                className='bx bxs-check-circle'></i>
                                                            </span>
                                                            </>

                                                        ) : (
                                                            <span className={'text-red-500'}><i
                                                                className='bx bxs-x-circle'></i> {unit.error}</span>
                                                        )
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    }

                                    </tbody>
                                </table>
                            </div>
                        </>
                    ) : (
                        <Loader label={'Loading history'}/>
                    )
                }
            </div>

        </div>
    )
}
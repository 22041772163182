import {useEffect, useState} from "react";
import apiClient from "../api/DevAPI";
import ProjectWorkspace from "../components/ProjectWorkspace";
import WhiteButton from "../components/WhiteButton";
import PrimaryButton from "../components/PrimaryButton";
import TextField from "../components/TextField";
import Loader from "../components/Loader";
import {Helmet} from "react-helmet";
import DashboardHeadmenu from "../components/DashboardHeadmenu";
import SelectField from "../components/SelectField";

export default function Dashboard() {

    const [projects, setProjects] = useState([]);
    const [selectedProject, setSelectedProject] = useState(null);
    const [isSubscribed, setIsSubscribed] = useState(null);

    const [newProjectName, setNewProjectName] = useState("");
    const [newProjectModal, setNewProjectModal] = useState(false);

    const [newProjectProcessing, setNewProjectProcessing] = useState(false);

    const [selectFieldProjectData, setSelectFieldProjectData] = useState([]);

    useEffect(() => {
        if (localStorage.getItem("token") === null) {
            localStorage.clear()
            document.location = '/login'
        }
        apiClient.get('/projects/?token=' + localStorage.getItem('token'))
            .then((res) => {
                setProjects(res.data.data);
                let projectsSelectList = []
                res.data.data.map((project) => {
                    projectsSelectList.push({
                        "display": project.name,
                        "value": project.id,
                    })
                })
                setSelectFieldProjectData(projectsSelectList)
                apiClient.get(`check-dev-sub/?user_iden=${localStorage.getItem('user_iden')}`)
                    .then(res => {
                        setIsSubscribed(res.data.is_subscriber);
                    })
            })
            .catch((err) => {
                localStorage.clear()
                document.location = '/login'
            })
    }, [])

    function updateProjects() {
        apiClient.get('/projects/?token=' + localStorage.getItem('token'))
            .then((res) => {
                setProjects(res.data.data);
                let projectsSelectList = []
                res.data.data.map((project) => {
                    projectsSelectList.push({
                        "display": project.name,
                        "value": project.id,
                    })
                })
                setSelectFieldProjectData(projectsSelectList)
                apiClient.get(`check-dev-sub/?user_iden=${localStorage.getItem('user_iden')}`)
                    .then(res => {
                        setIsSubscribed(res.data.is_subscriber);
                    })
            })
    }

    function CreateProject() {
        setNewProjectProcessing(true);
        apiClient.post('/projects/create/new', {
            token: localStorage.getItem('token'),
            project_name: newProjectName,
            username: localStorage.getItem('user_iden'),
        })
            .then((res) => {
                updateProjects()
                setNewProjectModal(false);
                setSelectedProject(res.data.project_id);
            })
            .catch((err) => {
                alert(err)
            })
            .finally(() => setNewProjectProcessing(false))
    }

    return (
        <>
            <DashboardHeadmenu projectsSelectList={selectFieldProjectData} setSelectedProject={setSelectedProject}
                               createProjectModalSwitcher={setNewProjectModal}/>
            <Helmet>
                <title>Dashboard - Beep</title>
                <meta name="description"
                      content="Beep — a service for sending notifications to your users via API. Easy to start, easy to use. Start free now."/>
                <meta name="keywords"
                      content="notifications, manage, buisness, beep, notifications for buisness, telegram"/>
                <link rel="canonical" href="https://beepme.org/dashboard/"/>

                <meta property="og:title" content="Beep - Notifications for your buisness"/>
                <meta property="og:description" content="Start sending notifications free"/>
                <meta property="og:url" content="https://beepme.org/dashboard/"/>
                <meta property="og:type" content="website"/>

            </Helmet>
            <div className={'bg-[#EEF6FF] min-h-screen pt-10'}>
                {
                    newProjectModal && (
                        <div
                            className={'fixed bg-black bg-opacity-50 w-screen h-screen flex justify-center items-center top-0 left-0 z-50'}>
                            <div className={'bg-white p-10 w-1/3 max-lg:w-full rounded-xl'}>
                                <h2 className={'text-3xl'}>Meet your new project</h2>
                                <p>Set name and go</p>

                                {
                                    isSubscribed ? (

                                        newProjectProcessing ? (
                                            <div className={'mt-10'}>
                                                <Loader label={'Setting up your project'}/>
                                            </div>
                                        ) : (
                                            <div className={'flex flex-col gap-5 mt-10'}>
                                                <TextField placeholder={'Project name'} setSource={setNewProjectName}/>
                                                <PrimaryButton fullWidth={true}
                                                               onClick={CreateProject}>Create</PrimaryButton>
                                                <WhiteButton fullWidth={true}
                                                             onClick={() => setNewProjectModal(false)}>Cancel</WhiteButton>
                                            </div>
                                        )
                                    ) : (
                                        <>
                                            <div
                                                className={'p-10 bg-red-500 text-white flex justify-between flex-col gap-5'}>
                                                <div>
                                                    <h3 className={'text-3xl'}>You have not subscription for Beep Dev
                                                        Project</h3>
                                                    <p>To create or manipulate projects you must be a subscriber of Beep
                                                        Dev
                                                        Project.</p>
                                                </div>
                                                <a href={'/dev-connect'} target={'_blank'}
                                                   rel={'noreferrer'}><WhiteButton
                                                    onClick={() => {
                                                    }} fullWidth={true}>Subscribe</WhiteButton></a>
                                            </div>
                                            <WhiteButton fullWidth={true}
                                                         onClick={() => setNewProjectModal(false)}>Cancel</WhiteButton>
                                        </>

                                    )
                                }
                            </div>
                        </div>
                    )
                }

                <div className={'container m-auto'}>
                    {
                        isSubscribed === false && (
                            <div
                                className={'p-10 bg-red-500 text-white flex justify-between lg:items-center max-lg:flex-col max-lg:gap-5'}>
                                <div>
                                    <h3 className={'text-3xl'}>You have not subscription for Beep Dev Project</h3>
                                    <p>To create or manipulate projects you must be a subscriber of Beep Dev Project.</p>
                                </div>
                                <a href={'/dev-connect'} target={'_blank'} rel={'noreferrer'}><WhiteButton onClick={() => {
                                }} fullWidth={true}>Subscribe</WhiteButton></a>
                            </div>
                        )
                    }
                    <div className={'flex max-sm:flex-col gap-20 max-sm:gap-10 p-10 items-start'}>
                        {/*<div className={'bg-white p-10 rounded-3xl w-1/3 max-sm:w-full'}>*/}
                        {/*    <h2 className={'text-3xl'}>Your projects</h2>*/}
                        {/*    <div className={'flex flex-col mt-3 mb-3 gap-2'}>*/}
                        {/*        {*/}
                        {/*            projects && (*/}
                        {/*                projects.map((project) =>*/}
                        {/*                    <div*/}
                        {/*                        className={'p-5 bg-[#FCE5FF] rounded-xl cursor-pointer transition-all'}*/}
                        {/*                        onClick={() => {*/}
                        {/*                            setSelectedProject(project.id)*/}
                        {/*                        }}>*/}
                        {/*                        <p className={'text-xl'}>{project.name}</p>*/}
                        {/*                        <p>{project.plan}</p>*/}
                        {/*                    </div>*/}
                        {/*                )*/}
                        {/*            )*/}
                        {/*        }*/}
                        {/*    </div>*/}
                        {/*    {*/}
                        {/*        isSubscribed && (*/}
                        {/*            <PrimaryButton fullWidth={true} onClick={() => setNewProjectModal(true)}>*/}
                        {/*                <i className='bx bx-plus'></i> Create Project*/}
                        {/*            </PrimaryButton>*/}
                        {/*        )*/}
                        {/*    }*/}
                        {/*</div>*/}
                        <div className={'flex-1 max-sm:w-full'}>
                            {
                                selectedProject ? (
                                    <ProjectWorkspace projectId={selectedProject}/>
                                ) : (
                                    <div className={'p-10 bg-white flex justify-center items-center text-center'}>
                                        <div>
                                            <h2 className={'text-2xl'}>No active workspace 👻</h2>
                                            <div className={'flex items-center justify-center gap-1 mt-5'}>
                                                <SelectField options={selectFieldProjectData}
                                                             placeholder={'Select a project'}
                                                             setSource={setSelectedProject}
                                                             createProjectModalSwitcher={setNewProjectModal}/>
                                                <p>or <span
                                                    className={'underline cursor-pointer'}
                                                    onClick={() => setNewProjectModal(true)}>create one</span> to
                                                    start work</p>
                                            </div>
                                        </div>

                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
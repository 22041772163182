import {useEffect, useState} from "react";
import apiClient from "../api/DevAPI";
import ToggleArea from "./ToggleArea";
import PrimaryButton from "./PrimaryButton";
import TextField from "./TextField";
import WhiteButton from "./WhiteButton";
import Loader from "./Loader";
import DangerButton from "./DangerButton";

export default function ProjectWorkspace({projectId}) {

    const [data, setData] = useState(null);
    const [customBotModal, setCustomBotModal] = useState(false);
    const [botToken, setBotToken] = useState("");
    const [customBotError, setCustomBotError] = useState(null);
    const [deleteProjectModal, setDeleteProjectModal] = useState(false);

    const [deleteProjectProcessing, setDeleteProjectProcessing] = useState(false);
    const [deleteProjectVerificationProccessing, setDeleteProjectVerificationProccessing] = useState(false);
    const [deleteProjectVerificationCode, setDeleteProjectVerificationCode] = useState(null);
    const [deleteProjectError, setDeleteProjectError] = useState(null);

    const [projectIdVisible, setProjectIdVisible] = useState(false);

    const [setupBotProcessing, setSetupBotProcessing] = useState(false);

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    useEffect(() => {
        setProjectIdVisible(false)
        setData(null)
        apiClient.get('/projects/' + projectId + '/?token=' + localStorage.getItem('token'))
            .then(res => {
                setData(res.data.data);
            })
            .catch(err => console.log(err));
    }, [projectId]);

    function updateData() {
        apiClient.get('/projects/' + projectId + '/?token=' + localStorage.getItem('token'))
            .then(res => {
                setData(res.data.data);
            })
            .catch(err => console.log(err));
    }

    function setCustomBot() {
        setCustomBotError(null);
        setSetupBotProcessing(true);
        apiClient.post('/projects/' + projectId + '/set-custom-bot/', {
            token: localStorage.getItem('token'),
            bot_token: botToken,
        })
            .then(res => {
                if (res.data.ok) {
                    setCustomBotModal(false);
                    updateData();
                } else {
                    if (res.data.error) {
                        setCustomBotError(res.data.error);
                    } else {
                        setCustomBotError("Failed to set up your bot. Make sure token is correct.");
                    }
                }
            })
            .finally(() => {
                setSetupBotProcessing(false);
            })
    }

    function startDeletingProject() {
        setDeleteProjectVerificationProccessing(true);
        setDeleteProjectProcessing(false);
        setDeleteProjectModal(true);

        apiClient.post('/verification_code/', {
            token: localStorage.getItem('token'),
        })
            .then(res => {
                setDeleteProjectVerificationProccessing(false);
            })
    }

    function deleteProject() {
        setDeleteProjectProcessing(true);
        apiClient.post(`/projects/${data.id}/delete/`, {
            token: localStorage.getItem('token'),
            verification_code: deleteProjectVerificationCode
        })
            .then(res => {
                if (res.data.ok) {
                    document.location.reload()
                } else {
                    setDeleteProjectProcessing(false)
                    if (res.data.error) {
                        setDeleteProjectError(res.data.error)
                    } else {
                        setDeleteProjectError("Unexpected error");
                    }
                }
            })
    }

    return (
        <>
            {
                customBotModal && (
                    <div
                        className={'fixed bg-black bg-opacity-50 w-screen h-screen flex justify-center items-center top-0 left-0 z-50'}>
                        <div className={'bg-white p-10 w-1/3 rounded-xl'}>
                            <h2 className={'text-3xl'}>Custom bot for project</h2>
                            <p>Connect or change custom TG bot for your project.</p>

                            {
                                setupBotProcessing ? (
                                    <div className={'mt-10'}>
                                        <Loader label={'Setting up your bot'}/>
                                    </div>
                                ) : (
                                    <div className={'flex flex-col gap-5 mt-10'}>
                                        <TextField secret={true} placeholder={'Bot token'} setSource={setBotToken}/>
                                        {
                                            setCustomBotError && (
                                                <p className={'text-red-500'}>{customBotError}</p>
                                            )
                                        }
                                        <PrimaryButton fullWidth={true} onClick={setCustomBot}>Set</PrimaryButton>
                                        <WhiteButton fullWidth={true}
                                                     onClick={() => setCustomBotModal(false)}>Cancel</WhiteButton>
                                    </div>
                                )
                            }


                        </div>
                    </div>
                )
            }

            {
                deleteProjectModal && (
                    <div
                        className={'fixed bg-black bg-opacity-50 w-screen h-screen flex justify-center items-center top-0 left-0 z-50'}>
                        <div className={'bg-white p-10 w-1/3 rounded-xl'}>
                            <h2 className={'text-3xl'}>Deleting your project</h2>
                            <p>Think twice</p>

                            {
                                deleteProjectProcessing ? (
                                    <div className={'mt-10'}>
                                        <Loader label={'Deleting your project'}/>
                                    </div>
                                ) : (
                                    deleteProjectVerificationProccessing ? (
                                        <div className={'mt-10'}>
                                            <Loader label={'Preparing for verification'}/>
                                        </div>
                                    ) : (
                                        <div className={'flex flex-col gap-5 mt-10'}>
                                            <TextField secret={false} placeholder={'Verification code'}
                                                       setSource={setDeleteProjectVerificationCode}/>
                                            {
                                                deleteProjectError && (
                                                    <p className={'text-red-500'}>{deleteProjectError}</p>
                                                )
                                            }
                                            <DangerButton fullWidth={true} onClick={deleteProject}>Submit and
                                                delete</DangerButton>
                                            <WhiteButton fullWidth={true}
                                                         onClick={() => setDeleteProjectModal(false)}>Cancel</WhiteButton>
                                        </div>
                                    )

                                )
                            }


                        </div>
                    </div>
                )
            }
            {
                data ? (
                    <div className={'w-full p-10 rounded-xl'}>
                        {
                            data && (
                                <div className={'w-full'}>
                                    <h2 className={'text-5xl mb-10'}>{data.name}</h2>

                                    <div className={'flex justify-between gap-10'}>
                                        <div className={'flex flex-col bg-white p-10 rounded-xl w-1/2 max-sm:w-full'}>
                                            <p>This project plan is</p>
                                            <h2 className={'text-3xl'}>{data.plan.name}</h2>

                                            <div className={'flex flex-col gap-5 mt-5'}>
                                                <div>
                                                    <div className={'flex justify-between items-center'}>
                                                        <p>Notifications limit</p>
                                                        <p className={'text-end'}>{numberWithCommas(data.plan.notifications_limit)}</p>

                                                    </div>

                                                    <div
                                                        className={'flex justify-between items-center'}>
                                                        <p className={''}>Authorization</p>
                                                        {
                                                            data.plan.authorization_available ? (
                                                                <span
                                                                    className={'text-green-500 flex items-center gap-1'}><i
                                                                    className='bx bxs-check-circle'></i> <label>Available</label></span>
                                                            ) : (
                                                                <span className={'text-red-500 flex items-center'}><i
                                                                    className='bx bxs-x-circle'></i> Not available</span>
                                                            )
                                                        }
                                                    </div>

                                                    <div
                                                        className={'flex justify-between items-center'}>
                                                        <p>Custom bot</p>
                                                        {
                                                            data.plan.custom_bot_available ? (
                                                                <span
                                                                    className={'text-green-500 flex items-center gap-1'}><i
                                                                    className='bx bxs-check-circle'></i> <label>Available</label></span>
                                                            ) : (
                                                                <span className={'text-red-500 flex items-center'}><i
                                                                    className='bx bxs-x-circle'></i> Not available</span>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className={'flex flex-col bg-white p-10 rounded-xl w-1/2 max-sm:w-full'}>
                                            <h2 className={'text-3xl'}>Notifications limits</h2>

                                            <div className={'mt-10'}>
                                                <p>{numberWithCommas(data.plan.notifications_limit - data.notifications_sent_period)} notifications
                                                    left</p>
                                                <div className={'h-2 bg-gray-400 rounded-full relative overflow-hidden'}>
                                                    <div className={'h-2 bg-red-500 rounded-full'}
                                                         style={{
                                                             width: String(100 / data.plan.notifications_limit * data.notifications_sent_period) + "%"
                                                         }}
                                                    ></div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div className={'ps-10 pe-10 pt-5 pb-5 bg-white mt-10 rounded-xl'}>
                                        <ToggleArea title={"General information"}>
                                            <div className={'flex'}>
                                                <div className={'font-bold flex-1 flex flex-col gap-5'}>
                                                    <p>Title</p>
                                                    <p>Project ID <span
                                                        className={'text-gray-500 text-sm font-normal'}>(keep in secret)</span>
                                                    </p>
                                                    <p>Plan</p>
                                                </div>
                                                <div className={'flex-1 flex flex-col gap-5'}>
                                                    <p>{data.name}</p>
                                                    <p>{
                                                        projectIdVisible ? (
                                                            data.id
                                                        ) : (
                                                            <span
                                                                className={'text-gray-500 text-sm font-normal underline cursor-pointer'}
                                                                onClick={() => setProjectIdVisible(true)}>Show</span>
                                                        )
                                                    }</p>
                                                    <p>{data.plan.name}</p>
                                                </div>
                                            </div>
                                        </ToggleArea>
                                    </div>

                                    <div className={'ps-10 pe-10 pt-5 pb-5 bg-white mt-10 rounded-xl'}>
                                        <ToggleArea title={"Custom Bot"}>
                                            {
                                                data.plan.custom_bot_available ? (
                                                    <div className={'flex justify-between items-center'}>
                                                        <div>
                                                            {
                                                                data.custom_bot ? (
                                                                    <p className={'text-green-500 flex gap-1 items-center'}>
                                                                        <i
                                                                            className='bx bxs-check-circle'></i> Custom
                                                                        bot is connected</p>
                                                                ) : (
                                                                    <p className={'text-red-500 flex gap-1 items-center'}><i
                                                                        class='bx bxs-x-circle'></i> Custom
                                                                        bot is not connected</p>
                                                                )
                                                            }
                                                        </div>
                                                        <div>
                                                            <PrimaryButton onClick={() => setCustomBotModal(true)}>
                                                                {
                                                                    data.custom_bot ? (
                                                                        'Change'
                                                                    ) : (
                                                                        'Connect'
                                                                    )
                                                                }
                                                            </PrimaryButton>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <p>Current plan of this project is not supporting Custom Bot</p>
                                                )
                                            }
                                        </ToggleArea>
                                    </div>

                                    <div className={'ps-10 pe-10 pt-5 pb-5 bg-white mt-10 rounded-xl'}>
                                        <ToggleArea title={"Notifications Service"}>
                                            <div className={'flex flex-col gap-5'}>
                                                <div>
                                                    <p><b>Subscribers:</b> {data.receivers}</p>
                                                    <p><b>Total notifications:</b> {data.notifications_sent}</p>
                                                </div>

                                                <div>
                                                    <a href={`/dashboard/notifications/${data.id}/history`}
                                                       target={'_blank'}
                                                       rel={'noreferrer'}><PrimaryButton onClick={() => {
                                                    }}>Get notifications history</PrimaryButton></a>
                                                </div>
                                            </div>
                                        </ToggleArea>
                                    </div>
                                </div>
                            )
                        }

                        <div className={'mt-10'}>
                            <DangerButton fullWidth={true} onClick={startDeletingProject}>Delete
                                project</DangerButton>
                        </div>

                    </div>
                ) : (
                    <div className={'p-10 bg-white flex justify-center items-center text-center'}>
                        <div>
                            <h2 className={'text-2xl'}>One moment</h2>
                            <p>Loading project <i className='bx bx-loader-alt bx-spin'></i></p>
                        </div>
                    </div>
                )
            }

        </>
    )
}
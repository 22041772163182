import React, {Component} from 'react';

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false, error: null, errorInfo: null};  // Сохраняем ошибку и информацию о ней
    }

    // Этот метод вызывается, если в любом из дочерних компонентов произошла ошибка
    static getDerivedStateFromError(error) {
        // Обновляем состояние, чтобы отобразить запасной UI
        return {hasError: true, error}; // Сохраняем ошибку в состоянии
    }

    // Этот метод вызывается, когда ошибка возникает, и тут можно отправить отчет о ней
    componentDidCatch(error, errorInfo) {
        this.setState({error, errorInfo});
        // Логируем ошибку или отправляем её на сервер
        console.error('Error caught by boundary:', error);
        console.error('Error info:', errorInfo);
    }

    render() {
        // Если произошла ошибка, показываем запасной UI
        if (this.state.hasError) {
            const {error} = this.state;

            return (
                <div className={'min-h-screen min-w-screen text-black'}>
                    <h1>It seems like things go wrong</h1>
                    <p>Please try again.</p>
                    {/* Отображаем код ошибки, если он есть */}
                    {error && <p>Error code: {error.code || 'Unknown'}</p>}
                    <p>Сообщение: {error.message}</p>
                </div>
            );
        }

        // Если ошибки нет, рендерим дочерние компоненты
        return this.props.children;
    }
}

export default ErrorBoundary;

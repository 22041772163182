import {useEffect, useState} from "react";
import Loader from "../components/Loader";
import axios from "axios";
import apiClient from "../api/DevAPI";

export default function DevConnect() {

    const [linkcode, setLinkcode] = useState(null);
    const [done, setDone] = useState(false);

    useEffect(() => {
        apiClient.get('link/')
            .then(res => {
                setLinkcode(res.data.link_code);
                const interval = setInterval(() => {
                    apiClient.get('link/check/?code=' + res.data.link_code)
                        .then((res) => {
                            setDone(true);
                            clearInterval(interval);
                        })
                        .catch((err) => {})
                }, 1000)
            })
    }, [])

    return (
        <div className={'w-screen h-screen flex justify-center items-center'}>
            <div className={'text-center'}>
                {
                    done ? (
                        <div>
                            <h2 className={'text-3xl mb-2'}>Done</h2>
                            <p>You have subscribed to Beep Dev! Now you can create projects.</p>
                        </div>
                    ) : (
                        <>
                            <div>
                                <h2 className={'text-3xl'}>Connect to Beep Dev</h2>
                                <p>To create or manipulate your projects you must be subscribed to our Beep Dev
                                    Project</p>
                            </div>
                            <div className={'mt-10'}>
                                {
                                    linkcode ? (
                                        <>
                                            <p>Here is your link code. Send it to Beep Bot.</p>
                                            <h2 className={'text-2xl'}>{linkcode}</h2>
                                        </>
                                    ) : (
                                        <Loader label={'Getting linkcode'}/>
                                    )
                                }
                            </div>
                        </>
                    )
                }

            </div>
        </div>
    )
}
import React, {Component} from 'react';
import PrimaryButton from "./PrimaryButton";
import TransparentButton from "./TransparentButton";

class NotFound extends Component {
    render() {


        return (
            <div className={'min-h-screen min-w-screen text-black flex max-sm:flex-col justify-center items-center gap-10'}>
                <div>
                    <h2 className={'text-6xl text-red-500'}>404</h2>
                </div>
                <div>
                    <h1 className={'text-3xl'}>Where are you going?</h1>
                    <p>We can't find the path</p>
                    <div className={'mt-5'}>
                        <a href={'/'}><TransparentButton onClick={() => {}} fullWidth={true}>Go to homepage</TransparentButton></a>
                    </div>

                </div>
            </div>
        );
    }
}

export default NotFound;

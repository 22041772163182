export default function Footer() {
    return (
        <footer className={'w-full flex mal-lg:flex-col justify-between items-center bg-white p-20'}>
            <div className={'flex flex-col gap-5'}>
                <p>Created by <a href={'https://x.com/dbaykaloff'} target={'_blank'} className={'underline'}>Daniel Baikalov</a></p>
                <div className={'flex gap-2'}>
                    <a href={'https://x.com/dbaykaloff'} target={'_blank'}><i className='bx bxl-twitter'></i></a>
                    <a href={'https://t.me/dbaykalov'} target={'_blank'}><i className='bx bxl-telegram'></i></a>
                </div>
            </div>
        </footer>
    )
}
import logo from '../media/logo192.png'
import PrimaryButton from "./PrimaryButton";
import TransparentButton from "./TransparentButton";
import SelectField from "./SelectField";
import {useEffect, useState} from "react";

export default function DashboardHeadmenu({projectsSelectList, setSelectedProject, createProjectModalSwitcher}) {

    const [projSelected, setProjSelected] = useState(null);

    useEffect(() => {
        setSelectedProject(projSelected);
    }, [projSelected]);

    function LogOut() {
        localStorage.removeItem("token");
        document.location = "/login";
    }

    return (
        <nav className="bg-white border-gray-200 z-50">
            <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
                <a href="/" className="flex items-center space-x-3 rtl:space-x-reverse">
                    <img src={logo} className="h-8" alt="Beep Logo"/>
                    <span
                        className="self-center text-2xl font-semibold whitespace-nowrap">Beep</span>
                </a>
                <button data-collapse-toggle="navbar-default" type="button"
                        className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
                        aria-controls="navbar-default" aria-expanded="false">
                    <span className="sr-only">Open main menu</span>
                    <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                         viewBox="0 0 17 14">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="M1 1h15M1 7h15M1 13h15"/>
                    </svg>
                </button>
                <div className="hidden w-full md:block md:w-auto" id="navbar-default">
                    <ul className="font-medium flex flex-col items-center gap-5 p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 md:bg-white">
                        <li>
                            <a href={"https://buildin.ai/worldz/share/16c4400e-725e-46d5-8eca-9168213b827a"}
                               target={"_blank"}
                               className={'flex justify-center items-center gap-2 bg-black text-white p-2 rounded'}>Docs <i
                                className='bx bxs-book-alt cursor-pointer'></i></a>
                        </li>

                        <li>
                            <SelectField options={projectsSelectList} placeholder={'Select Project'}
                                         setSource={setProjSelected}
                                         createProjectModalSwitcher={createProjectModalSwitcher}/>
                        </li>


                        <li>
                            <div className={'flex items-center gap-2'}>
                                {localStorage.getItem("user_iden")}
                                <i className='bx bxs-exit cursor-pointer' onClick={LogOut}></i>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>

    )
}
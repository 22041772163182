import {useState} from "react";
import PrimaryButton from "../../PrimaryButton";
import axios from "axios";
import Loader from "../../Loader";
import TransparentButton from "../../TransparentButton";
import WhiteButton from "../../WhiteButton";
import TextField from "../../TextField";

export default function AuthorizationDemo() {

    const [step, setStep] = useState(-1);

    const [beepDemoProjectId, setBeepDemoProjectId] = useState('b5240985-133a-4f86-b33c-3feb0df01397');
    const [linkcode, setLinkcode] = useState('');
    const [processing, setProcessing] = useState(false);
    const [username, setUsername] = useState("");
    const [acode, setAcode] = useState("");
    const [usernameError, setUsernameError] = useState(null);
    const [token, setToken] = useState("");

    let checkInterval = null;

    function StartDemo() {
        setStep(prev => prev + 1);
    }

    function SendAuthCode() {
        setUsernameError(null)
        setProcessing(true);
        axios.get(`https://beep.api.worldz.tech/api/auth/${beepDemoProjectId}/${username}/acode/`)
            .then((res) => {
                setStep(prev => prev + 1);
            })
            .catch((err) => {
                setUsernameError('Are you sure is your username?')
            })
            .finally(() => {
                setProcessing(false);
            })
    }

    function Authorize() {
        setProcessing(true);
        axios.post(`https://beep.api.worldz.tech/api/auth/${beepDemoProjectId}/${username}/acode/`, {
            code: acode
        })
            .then((res) => {
                setToken(res.data.token);
                setStep(prev => prev + 1);
            })
            .catch((err) => {
                setUsernameError('Invalid code')
            })
            .finally(() => {
                setProcessing(false);
            })
    }

    return (
        <div>
            <h2 className={'text-2xl'}>Authentication</h2>
            <p>Let's see how users can be authenticated by Beep</p>


            {
                processing ? (
                    <Loader label={'Please wait'}/>
                ) : (
                    <div className={'mt-10'}>

                        {
                            step === -1 && (
                                <div>
                                    <PrimaryButton fullWidth={true} onClick={StartDemo}>Start demo</PrimaryButton>
                                </div>
                            )
                        }

                        {
                            step === 0 && (
                                <div>
                                    <p className={'text-lg'}><b>Step 1.</b> Know username</p>
                                    <p>First of all we need to now username of the user.</p>

                                    <div
                                        className={'mt-10 bg-indigo-950 p-10 rounded-xl flex justify-center gap-20 items-center flex-1'}>
                                        <div>
                                            <div className={'text-2xl mb-5 text-white'}>
                                                <p>Login via Beep</p>
                                            </div>

                                            {
                                                usernameError && (
                                                    <p className={'text-red-500'}>{usernameError}</p>
                                                )
                                            }
                                            <TextField setSource={setUsername} placeholder={'Beep Username'}/>
                                            <a href={'https://t.me/beep_demo_bot'} className={'underline mt-5 text-white'} target={'_blank'} rel={'noreferrer'}><p>Do not have it? Let's create one - just start a bot!</p></a>

                                            <div className={'mt-5'}>
                                                <WhiteButton fullWidth={true} onClick={SendAuthCode}>Next</WhiteButton>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            )
                        }

                        {
                            step === 1 && (
                                <div>
                                    <p className={'text-lg'}><b>Step 2.</b> Enter auth code</p>
                                    <p>After you know username, Beep sends one-time password to user.</p>

                                    <div
                                        className={'mt-10 bg-indigo-950 p-10 rounded-xl flex justify-center gap-20 items-center flex-1'}>
                                        <div>
                                            <div className={'text-2xl mb-5 text-white'}>
                                                <p>Login via Beep</p>
                                            </div>

                                            <p className={'text-white'}>We sent you authentication code</p>
                                            <TextField secret={true} setSource={setAcode} placeholder={'Auth code'}/>

                                            {
                                                usernameError && (
                                                    <p className={'text-red-500'}>{usernameError}</p>
                                                )
                                            }

                                            <div className={'mt-5'}>
                                                <WhiteButton fullWidth={true} onClick={Authorize}>Login</WhiteButton>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            )
                        }

                        {
                            step === 2 && (
                                <div>
                                    <p className={'text-lg'}><b>Step 3.</b> Use user's token</p>
                                    <p>After successful authentication we provides you token, that you can validate via Beep API.</p>

                                    <div
                                        className={'mt-10 bg-indigo-950 text-white p-10 rounded-xl flex justify-center gap-20 items-center flex-1'}>
                                        <div>
                                            <div className={'text-2xl mb-5'}>
                                                <p>Welcome back, {username}</p>
                                            </div>

                                            <p className={'break-all'}>Your token is {token} (keep it in secret) </p>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                )
            }


        </div>
    )
}
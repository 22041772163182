import TextField from "../components/TextField";
import {useEffect, useState} from "react";
import PrimaryButton from "../components/PrimaryButton";
import Loader from "../components/Loader";
import apiClient from "../api/DevAPI";
import {Helmet} from "react-helmet";

export default function DeveloperLogin() {

    const [username, setUsername] = useState("");
    const [authCode, setAuthCode] = useState("");
    const [step, setStep] = useState(1);

    const [processing, setProcessing] = useState(false);

    useEffect(() => {
        if (localStorage.getItem('token') !== null) {
            document.location = '/dashboard';
        }
    }, [])

    async function GetAuthCode() {
        setProcessing(true);
        apiClient.get('auth/?username=' + username)
            .then(res => {
                setStep((prev) => prev + 1);
            })
            .catch(err => console.log(err))
            .finally(() => setProcessing(false))
    }

    async function Login() {
        setProcessing(true);
        apiClient.post('auth/', {username: username, auth_code: authCode})
            .then(res => {
                localStorage.setItem('token', res.data.token);
                localStorage.setItem('user_iden', username)
                document.location = '/dashboard'
            })
            .catch(err => console.log(err))
            .finally(() => setProcessing(false))
    }

    return (
        <>
            <Helmet>
                <title>Login - Beep</title>
                <meta name="description"
                      content="Login to your dashboard to create amazing new projects."/>
                <meta name="keywords"
                      content="notifications, manage, buisness, beep, notifications for buisness, telegram"/>
                <link rel="canonical" href="https://beepme.org/login/"/>

                <meta property="og:title" content="Beep - Notifications for your buisness"/>
                <meta property="og:description" content="Start sending notifications free"/>
                <meta property="og:url" content="https://beepme.org/login/"/>
                <meta property="og:type" content="website"/>

            </Helmet>
            <div className={'flex justify-center items-center w-screen h-screen bg-[#EEF6FF]'}>
                <div className={'p-20 bg-white rounded-xl'}>
                    <h2 className={'text-4xl'}>Beep For Developers</h2>
                    <p>Login via Beep</p>

                    <div className={'mt-10'}>
                        {
                            processing ? (
                                <Loader label={'Loading'}/>
                            ) : (
                                step === 1 ? (
                                    <div className={'flex flex-col gap-5'}>
                                        <TextField setSource={setUsername} placeholder={'Beep username'}/>
                                        <PrimaryButton fullWidth={true} onClick={GetAuthCode}>Next</PrimaryButton>

                                        <div className={'text-gray-500 underline'}>
                                            <a target={'_blank'} rel={'noreferrer'} href={'https://t.me/devs_beep_bot'}>
                                                <p>Do not have Beep username? Just
                                                    start the bot.</p></a>
                                        </div>

                                    </div>
                                ) : (
                                    step === 2 ? (
                                        <div className={'flex flex-col gap-5'}>
                                            <p>Auth code has beem sent</p>
                                            <TextField setSource={setAuthCode} placeholder={'Auth code'}/>
                                            <PrimaryButton fullWidth={true} onClick={Login}>Login</PrimaryButton>
                                        </div>
                                    ) : (<></>)
                                )
                            )
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
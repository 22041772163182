import {useState} from "react";

export default function ToggleArea({title, border=false, children}) {

    const [visible, setVisible] = useState(false);

    return (
        <div className={'mt-10 pb-10 ' + (border ? "border-b" : "")}>
            <div className={'flex justify-between items-center cursor-pointer'} onClick={() => setVisible(!visible)}>
                <h3 className={'text-xl font-bold'}>{title}</h3>
                <div className={' text-3xl'}>
                    {
                        visible ? (
                            <i className='bx bxs-chevron-up'></i>

                        ) : (
                            <i className='bx bxs-chevron-down'></i>
                        )
                    }
                </div>
            </div>

            {
                visible && (
                    <div className={'mt-10'}>
                        {children}
                    </div>
                )
            }
        </div>
    )
}
import {useState} from "react";
import PrimaryButton from "../../PrimaryButton";
import axios from "axios";
import Loader from "../../Loader";
import TransparentButton from "../../TransparentButton";
import WhiteButton from "../../WhiteButton";

export default function NotificationsDemo() {

    const [step, setStep] = useState(-1);

    const [beepDemoProjectId, setBeepDemoProjectId] = useState('b5240985-133a-4f86-b33c-3feb0df01397');
    const [linkcode, setLinkcode] = useState('');
    const [processing, setProcessing] = useState(false);
    const [username, setUsername] = useState("");

    let checkInterval = null;

    function StartDemo() {
        setProcessing(true);
        axios.post(`https://beep.api.worldz.tech/api/${beepDemoProjectId}/link/create/`)
            .then((res) => {
                setLinkcode(res.data.code);
                setStep(prev => prev + 1);
                setProcessing(false)

                checkInterval = setInterval(() => {
                    axios.get(`https://beep.api.worldz.tech/api/${beepDemoProjectId}/link/check/${res.data.code}`)
                        .then(res => {
                            if (res.data.accepted) {
                                setUsername(res.data.username);
                                clearInterval(checkInterval);
                                setStep(prev => prev + 1);
                            }
                        })
                }, 1000)
            })
    }

    function SendDemoInvoice() {
        axios.post(`https://beep.api.worldz.tech/api/notifications/${beepDemoProjectId}/${username}/`, {
            title: "Payment for subscription",
            body: "Hi! We are going to renew your subscription.\n\nCost: $4.99\n\nPay here: https://beepme.org/"
        })
    }

    function SendDemoNotification() {
        axios.post(`https://beep.api.worldz.tech/api/notifications/${beepDemoProjectId}/${username}/`, {
            title: "Checkout our new products",
            body: "Hello! We are so proud to introduce our brand new products.\n\nhttps://beepme.org/"
        })
    }

    return (
        <div>
            <h2 className={'text-2xl'}>Sending notifications</h2>
            <p>Let's see how users get notifications with Beep</p>


            {
                processing ? (
                    <Loader label={'Please wait'}/>
                ) : (
                    <div className={'mt-10'}>

                        {
                            step === -1 && (
                                <div>
                                    <PrimaryButton fullWidth={true} onClick={StartDemo}>Start demo</PrimaryButton>
                                </div>
                            )
                        }

                        {
                            step === 0 && (
                                <div>
                                    <p className={'text-lg'}><b>Step 1.</b> Connect to bot</p>
                                    <p>To get notifications from your project users must be subscribed to it. So to do it
                                        you have
                                        to generate a link code and share it with user.</p>

                                    <div
                                        className={'mt-10 bg-indigo-950 text-white p-10 rounded-xl flex max-sm:flex-col justify-between gap-20 items-center flex-1'}>
                                        <div>
                                            <div className={'bg-white text-indigo-950 ps-5 font-bold rounded-xl mb-5'}>
                                                <p>Hi! Subscribe us via Beep.</p>
                                            </div>

                                            <div>
                                                <p>Here is your link code. Send it to our bot and then return.</p>
                                                <div className={' mt-0 rounded-xl text-3xl'}>
                                                    {linkcode}
                                                </div>
                                            </div>
                                        </div>

                                        <div className={'flex-1'}>
                                            <a href={'https://t.me/beep_demo_bot'} target={'_blank'} rel={'noreferrer'}><WhiteButton onClick={() => {}} fullWidth={true}>Go
                                                to demo bot</WhiteButton></a>
                                        </div>

                                    </div>
                                </div>
                            )
                        }

                        {
                            step === 1 && (
                                <div>
                                    <p className={'text-lg'}><b>Step 2.</b> Send notifications</p>
                                    <p>That's it! After user has subscribed to your project, you can send notifications. Try it!</p>

                                    <div
                                        className={'mt-10 bg-indigo-950 text-white p-10 rounded-xl'}>
                                        <div>
                                            <div>
                                                <p className={'text-xl'}>What's up, {username}?</p>

                                                <div className={'mt-10 flex flex-col gap-5'}>
                                                    <WhiteButton fullWidth={true} onClick={SendDemoInvoice}>Send demo invoice</WhiteButton>
                                                    <WhiteButton fullWidth={true} onClick={SendDemoNotification}>Send demo notification</WhiteButton>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                )
            }


        </div>
    )
}